import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
  Form,
  Input,
  Button,
  Row,
  Col,
  message,
  Select,
  DatePicker,
  TimePicker,
} from "antd";

import { TaskService } from "../../../../../../services/managerTasks";
import loadingSVG from "../../../../../../assets/images/loading.svg";

import moment from "moment";

const { Option } = Select;
const { RangePicker } = DatePicker;


function FormTasks(props) {
  const { task, refreshTable, toggleFormVisibility } = props;
  const signin = useSelector((state) => state.signin);

  const [loading, setLoading] = useState(false);
  const [loadingTask, setLoadingTask] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [titleTask, setTitleTask] = useState();
  const [typeTask, setTypeTask] = useState("");
  const [urgencyTask, setUrgencyTask] = useState("");
  const [actionTask, setActionTask] = useState("");
  const [form] = Form.useForm();

  const handleTypeChange = (value) => setTypeTask(value);
  const handleUrgencyChange = (value) => setUrgencyTask(value);
  const handleTitleChange = (e) => setTitleTask(e.target.value);

  useEffect(() => {
    if (!task || Object.keys(task).length === 0) {
      form.resetFields();
    } else {
      form.setFieldsValue(task);
    }
  }, [task, form]);

  const onFinish = async (values) => {
    setLoading(true);

    let actionTask;
    if (typeTask === "Operacional" && urgencyTask === "Urgente") {
      actionTask = "Delegue para um colaborador";
    } else if (typeTask === "Operacional" && urgencyTask === "Não Urgente") {
      actionTask = "Contrate um terceiro para executar";
    } else if (typeTask === "Estratégico" && urgencyTask === "Urgente") {
      actionTask = "Faça você mesmo";
    } else if (typeTask === "Estratégico" && urgencyTask === "Não Urgente") {
      actionTask = "Delegue para um líder de equipe ou colaborador sênior";
    }

      try {
        await TaskService.create({
          ...values,
          is_completed: isCompleted,
          user_id: signin.user.id,
          title: titleTask,
          type: typeTask,
          urgency: urgencyTask,
          action: actionTask,
        });
        message.success("Tarefa criada com sucesso!");
        props.refreshTable({});
        toggleFormVisibility();
      } catch (error) {
        message.error("Erro ao criar a tarefa!");
      } finally {
        setLoading(false);
      }
  };

  return loadingTask ? (
    <>
      <div className="loading-centar">
        <img src={loadingSVG} alt="loading" height={80} />
      </div>
      <h2 className="text-loading">Carregando...</h2>
    </>
  ) : (
    <Form
    form={form}
    onFinish={onFinish}
    layout="vertical"
    >
          <Form.Item
            name="title"
            label="Descrição da tarefa"
            initialValue={task?.title}
            rules={[{ required: true,message: "Por favor, insira a descrição da tarefa" }]}
          >
            <Input placeholder="Descreva a tarefa (máximo: 50 caracteres)" value={titleTask} maxlength="50" onChange={handleTitleChange}/>
          </Form.Item>
          <Form.Item
            name="type"
            label="Tipo de Tarefa"
            initialValue={task?.type}
            rules={[{ required: true, message: "Por favor, selecione o tipo de tarefa" }]}
          >
            <Select placeholder="Selecione uma opção" value={typeTask} onChange={handleTypeChange}>
              <Option value="Operacional">Operacional</Option>
              <Option value="Estratégico">Estratégico</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="urgency"
            label="Urgência"
            initialValue={task?.urgency}
            rules={[{ required: true, message: "Por favor, selecione a urgência" }]}
          >
            <Select placeholder="Selecione uma opção" value={urgencyTask} onChange={handleUrgencyChange}>
              <Option value="Urgente">Urgente</Option>
              <Option value="Não Urgente">Não Urgente</Option>
            </Select>
          </Form.Item>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button type="primary" htmlType="submit" loading={loading}>
              Adicionar Tarefa
            </Button>
          </div>
    </Form>
  );
}

export default FormTasks;