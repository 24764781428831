import AWS from "aws-sdk";
import axiosClient from "../utils/api";
import { getFileType } from "../utils/file_type";

class S3Client {
  constructor(bucketName, region, accessKeyId, secretAccessKey) {
    this.bucketName = bucketName;

    this.s3 = new AWS.S3({
      apiVersion: "2006-03-01",
      region: region || process.env.REACT_APP_AWS_REGION,
      accessKeyId: accessKeyId || process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: secretAccessKey || process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    });
  }

  async upload(formData, variables) {
    return await axiosClient.post(
      `/files/upload?${new URLSearchParams(variables)}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  }

  async deleteFile(fileKey) {
    return await axiosClient.delete(`/files/delete?file_key=${fileKey}`);
  }

  async fetchFile(imageKey) {
    const params = {
      Bucket: this.bucketName,
      Key: imageKey,
    };

    try {
      const file = await this.s3.getObject(params).promise();

      const objectString = file.Body.toString();

      // Verifica se o arquivo é base64
      if (objectString.match("^data")) {
        return objectString;
      }

      const mimeType = getFileType(imageKey).mimetype;
      const blob = new Blob([file.Body.buffer], { type: mimeType });

      if (blob.size === 0) return null;

      return URL.createObjectURL(blob);
    } catch (error) {
      console.error("Error fetching file:", error);
      throw error;
    }
  }
}

export default S3Client;