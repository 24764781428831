import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

import Main from "../../../Layout/Main";
import MainCrud from "../../../../components/MainCrud";
import FormTasks from "./Components/FormTasks/index";
import styles from "./styles";

import { TaskService } from "../../../../services/managerTasks";
import loadingSVG from "../../../../assets/images/loading.svg";

import { message, Divider, Empty, Button, Popconfirm, Checkbox, Switch, Modal, Dropdown, Space, Col, Row } from "antd";

import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import MenuComponent from "../../../../components/MenuComponent";
import { itensMenu } from "../itens-menu";
import { checkControl } from "../../../../utils/access_control";

function Task(props) {
  const signin = useSelector((state) => state.signin);

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [tasks, setTasks] = useState();
  const [task, setTask] = useState({});
  const [checked, setChecked] = useState(false);
  const [filters, setFilters] = useState({
    type: null,
    urgency: null,
    action: null,
  });
  const [selectedButton, setSelectedButton] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const toggleFormVisibility = () => {
    setTask({});
    setIsModalVisible(!isModalVisible);
  };
  
  useEffect(() => {
    list(); // Carrega as tarefas com base no estado inicial de "checked"
  }, [checked, page, filters]);

  const breadcrumb = [
    {
      title: "Gestão",
      path: props.modulePath,
    },
    {
      title: "Tarefas",
      path: props.modulePath + "/administrative/task",
    },
  ];

  let columns = [
    {
      title: "Tarefa",
      dataIndex: "title",
      width: "50%",
      render: (text, record) => formatRowStyle(text, record),
    },
    {
      title: "Tipo de Tarefa",
      dataIndex: "type",
      width: "10%",
      render: (text, record) => formatRowStyle(text, record),
    },
    {
      title: "Urgência",
      dataIndex: "urgency",
      width: "10%",
      render: (text, record) => formatRowStyle(text, record),
    },
    {
      title: "Ação Recomendada",
      dataIndex: "action",
      width: "25%",
      render: (text, record) => formatRowStyle(text, record),
    },
    {
      title: "Concluída",
      dataIndex: "is_completed",
      width: "5%",
      align: "center",
      render: (is_completed, record) => (
        <Checkbox
          justify={"center"}
          checked={is_completed}
          onChange={() => updateTaskComplete(record)}
        />
      ),
    },
    //BOTÃO DE DELEÇÃO QUE SERÁ IMPLEMENTADO NA PRÓXIMA VERSÃO
    // {
    //   render: (data) => (
    //     <div>
    //       <Popconfirm
    //         title="Deseja remover a tarefa?"
    //         onConfirm={() => removeTask(data)}
    //         okText="Sim"
    //         cancelText="Não"
    //       >
    //         <Button type="danger" shape="circle" ghost size="small">
    //           <DeleteOutlined />
    //         </Button>
    //       </Popconfirm>
    //     </div>
    //   ),
    //   align: "right",
    // },
  ];

  const list = async () => {
    setLoading(true);
    const offset = page !== 1 ? rowsPerPage * (page - 1) : 0;
    const is_completed = checked ? "true" : "false";
    const activeFilters = Object.entries(filters).reduce((acc, [key, value]) => {
      if (value) acc[key] = value;
      return acc;
    }, {});
    try {
      const response = await TaskService.index({
        limit: rowsPerPage,
        offset,
        is_completed,
        filters: activeFilters
    });
      setTasks(response);
    } catch (error) {
      message.error("Erro ao carregar dados!");
    } finally {
      setLoading(false);
    }
  };

  const updateFilters = (key, value) => {
    const newFilters = { type: null, urgency: null, action: null };
    if (filters[key] !== value) {
        newFilters[key] = value;
        setSelectedButton(key);
    } else {
        setSelectedButton(null);
    }
    setFilters(newFilters);
    setPage(1);
};
  
  const completedSwitch = (checked) => {
    setChecked(checked); // Atualiza o estado diretamente
    const status = checked ? "Exibindo tarefas concluídas!" : "Exibindo tarefas pendentes!";
    message.info(status);
  };

  const updateTaskComplete = async (task) => {
    const updatedTask = { ...task, is_completed: !task.is_completed };
    try {
      await TaskService.update(updatedTask, task.id);
      message.success("Status da tarefa atualizado com sucesso!");
      list(checked); // Atualiza a lista de tarefas após alteração
    } catch (error) {
      message.error("Erro ao atualizar o status da tarefa.");
    }
  };


  async function handleTableChange(pagination) {
    setPage(pagination.current);
  }

  const refreshTable = (value) => {
    setTask(value);
    list();
  };

  const formatRowStyle = (text, record) => {
    let style = {};
    if (record.urgency === "Urgente" && record.type === "Estratégico") {
      style = { fontWeight: "bold", color: "red" };
    } else if (record.type === "Estratégico") {
      style = { fontWeight: "bold" };
    }
    return <span style={style}>{text}</span>;
  };

  const removeTask = async (value) => {
    setLoading(true);

    TaskService.delete(value.id)
      .then(() => message.success("Tarefa deletada com sucesso!"))
      .catch(() => message.error("Erro ao deletar a tarefa!"))
      .finally(() => list());
  };

  return (
    <>
      <Helmet>
        <title>Tarefas - webGM</title>
      </Helmet>
      <Main
        moduleName={props.moduleName}
        moduleMenu={
          <MenuComponent
            itensMenu={itensMenu}
            modulePath={props.modulePath}
            selectedMenu="8"
          />
        }
        breadcrumb={breadcrumb}
        displayMenuButtons={"flex"}
        buttonHeader={
          <>
            <span
              style={checkControl(
                signin.user.profiles,
                "check_resources_inactivate"
              )}
            >
              <Switch
                checkedChildren="Concluídas"
                unCheckedChildren="Pendentes"
                checked={checked}
                onChange={completedSwitch}
                style={{ marginRight: "5px", marginTop: "5px" }}
              />
            </span>
            <Button
              type="primary"
              size="middle"
              icon={<PlusOutlined />}
              onClick={toggleFormVisibility}
              style={{
                marginLeft: "20px",
              }}
            >
              Nova Tarefa
            </Button>
          </>
        }
      >
        <Modal
          title="Nova Tarefa"
          visible={isModalVisible}
          onCancel={toggleFormVisibility}
          footer={null}
        >
          <FormTasks 
          refreshTable={refreshTable} 
          task={{}} 
          toggleFormVisibility={toggleFormVisibility}
          />
        </Modal>
        <Row justify={"center"} style={{ marginBottom: "1rem" }}>
          <Col xs={24} sm={12} align="middle" >
            <Col>
              <h1 style={{ fontSize: "20px"}}>
                Filtre as tarefas por:
              </h1>
            </Col>
            <Row justify={"center"} style={{ marginBottom: "1rem" }}>
            <Col className="select-container">
              <Dropdown 
                menu={{ 
                  items: [
                    { key: "1", label: "Operacional" },
                    { key: "2", label: "Estratégico" },
                  ], 
                  onClick: ({ key }) => {
                    const newValue = key === "1" ? "Operacional" : "Estratégico";
                    updateFilters("type", newValue);
                  }
                }}
                >
                <Button
                  style={styles.getButtonStyle(filters.type !== null)}
                  onClick={() => {
                    if (selectedButton === "type") {
                      updateFilters("type", null);
                      setSelectedButton(null); 
                    }
                  }}
                >
                  Tipo de Tarefa
                </Button>
              </Dropdown>
              <Dropdown 
                menu={{ 
                  items: [
                    { key: "1", label: "Urgente" },
                    { key: "2", label: "Não Urgente" },
                  ], 
                  onClick: ({ key }) => {
                    const newValue = key === "1" ? "Urgente" : "Não Urgente";
                    updateFilters("urgency", newValue);
                  }
                }}
                >
                <Button
                  style={styles.getButtonStyle(filters.urgency !== null)}
                  onClick={() => {
                    if (selectedButton === "urgency") {
                      updateFilters("urgency", null);
                      setSelectedButton(null);
                    }
                  }}
                >
                  Urgência
                </Button>
              </Dropdown>
              <Dropdown 
                menu={{ 
                  items: [
                    { key: "1", label: "Delegue para um colaborador" },
                    { key: "2", label: "Contrate um terceiro para executar" },
                    { key: "3", label: "Faça você mesmo" },
                    { key: "4", label: "Delegue para um líder de equipe ou colaborador sênior" },
                  ], 
                  onClick: ({ key }) => {
                  const actionValues = [
                    "Delegue para um colaborador",
                    "Contrate um terceiro para executar",
                    "Faça você mesmo",
                    "Delegue para um líder de equipe ou colaborador sênior",
                  ];
                  const newValue = actionValues[key - 1];
                  updateFilters("action", newValue);
                }
                }}
                >
                <Button
                  style={styles.getButtonStyle(filters.action !== null)}
                  onClick={() => {
                    if (selectedButton === "action") {
                      updateFilters("action", null);
                      setSelectedButton(null); 
                    }
                  }}
                >
                  Ação Recomendada
                </Button>
              </Dropdown>
              </Col>
              </Row>
              </Col>
              </Row>
        {loading ? (
          <>
            <div className="loading-centar">
              <img src={loadingSVG} alt="loading" height={80} />
            </div>
            <h2 className="text-loading">Carregando...</h2>
          </>
        ) : tasks && tasks.rows.length === 0 ? (
          <Empty description="Nenhum tarefa cadastrada." />
        ) : (
          <MainCrud
            columnsTable={columns}
            dataSource={tasks && tasks.rows.length !== 0 ? tasks.rows : []}
            loading={loading}
            page={page}
            rowsPerPage={rowsPerPage}
            countPages={tasks && tasks.count ? tasks.count : 0}
            handleTableChange={handleTableChange}
            profiles={signin.user.profiles}
            displayMainCrud="none"
            classTag={[]}
          />
        )}
      </Main>
    </>
  );
}

export default Task;