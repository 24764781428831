import { instanceApiAuth } from "../../src/utils/apiProtheus";


export const ContractApprover = {
  getContracts: async (tenantID, code_saller, limit, offset) =>
    (await instanceApiAuth(tenantID)).get(`/all/PlanosAppComercial/v1/get-propostas-revisao?cod_lider=${code_saller}&limit=${limit}&offset=${offset}`),

  getContractDetail: async (tenantID, contract) =>
    (await instanceApiAuth(tenantID)).get(`/all/PlanosAppComercial/v2/get-contrato?contrato=${contract}`),

  activeContract: async (tenantID, contract, date, name_user) =>
    (await instanceApiAuth(tenantID)).put("/all/Ativacao/ativarContratocontrato", {
      contrato: contract,
      vencimento: date,
      nomeuser: name_user
    }),

  updatedDependentes: async (tenantID, contract, dependents) =>
    (await instanceApiAuth(tenantID)).put("/all/PlanosAppComercial/v1/update-dependentes", {
      contrato: contract,
      dependentes: dependents
    }),

  updatedContract: async (tenantID, contract, fullprice, tx_titular, tx_depen, carencia, dt_carencia, red_carencia) =>
    (await instanceApiAuth(tenantID)).put("/all/PlanosAppComercial/v1/update-carencia", {
      contrato: contract,
      valor_total: fullprice,
      tx_titular: tx_titular,
      tx_depen: tx_depen,
      carencia: carencia,
      dt_carencia: dt_carencia,
      red_carencia: red_carencia
    }),
};
