export const itensMenu = [
  {
    key: 0,
    permission: "access_menu_home",
    url: "/",
    title: "Início",
    icon: "HomeOutlined",
  },
  {
    key: 1,
    permission: "access_manager_panel",
    url: "/tasks",
    title: "Tarefas",
    icon: "ProfileOutlined",
  },
];
