import {
  FilePdfOutlined,
  FileImageOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import { Button, Tooltip, Divider } from "antd";
import { saveAs } from "file-saver";
import styles from "./styles.module.css";
import { useIsMobile } from "../../../../../utils/isMobile";
import S3Client from "../../../../../services/classAws";

export const AttachmentsViewer = ({ attachments = [] }) => {
  const [currentAttachment, setCurrentAttachment] = useState(null);
  const [attachmentUrl, setAttachmentUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const isMobile = useIsMobile();
  const [imageZoom, setImageZoom] = useState(false);

  useEffect(() => {
    if (attachments.length > 0 && !currentAttachment) {
      setCurrentAttachment(attachments[0]); // Define o primeiro anexo como padrão.
    }
  }, [attachments]);

  useEffect(() => {
    if (currentAttachment) {
      setLoading(true);
      setAttachmentUrl(null);

      const aws = new S3Client(process.env.REACT_APP_AWS_BUCKET_NAME_KNOWLEDGE)
      aws.fetchFile(currentAttachment.url).then((url) => {
        setAttachmentUrl(url);
      }).finally(() => {
        setLoading(false);
      });
    }
  }, [currentAttachment]);

  const handleDownload = () => {
    saveAs(attachmentUrl, currentAttachment?.name || "download");
  };

  const handleClickAttachment = (attachment) => {
    console.log(attachment)
    setCurrentAttachment(attachment);
  };

  return (
    <div
      className={styles.container}
      style={{
        flexDirection: isMobile ? "column" : "row",
        padding: "16px",
        border: "1px solid #d9d9d9",
        borderRadius: "8px",
        height: '700px'
      }}
    >
      <div className={styles.view_content}>
        {loading ? (
          <LoadingOutlined style={{ fontSize: 34 }} spin />
        ) : currentAttachment?.url?.endsWith(".pdf") ? (
          <embed
            src={attachmentUrl}
            type="application/pdf"
            width="100%"
            height="100%"
          ></embed>
        ) : (
          <img
            alt={currentAttachment?.name}
            className={styles.attachment_img}
            style={{
              height: imageZoom ? "auto" : "100%",
              cursor: imageZoom ? "zoom-out" : "zoom-in",
            }}
            onClick={() => setImageZoom(!imageZoom)}
            src={attachmentUrl}
            loading="lazy"
          />
        )}
      </div>
      <Divider
        type={isMobile ? "horizontal" : "vertical"}
        style={{ height: "100%" }}
      />
      <div className={styles.aside_container}>
        <div
          className={styles.items_content}
          style={{
            display: "flex",
            flexDirection: isMobile ? "row" : "column",
            overflow: "auto",
            gap: "8px",
          }}
        >
          {attachments.map((item, index) => (
            <Tooltip key={index} title={item.name} placement="left">
              <button
                onClick={() => handleClickAttachment(item)}
                style={{
                  backgroundColor:
                    currentAttachment?.url === item.url ? "#f5f5f5" : "#fff",
                  padding: ".5rem",
                  width: 150,
                  height: 100,
                  overflow: "hidden",
                  border: "1px solid #d9d9d9",
                  borderRadius: "4px",
                }}
                className={styles.attachment_item}
              >
                {item.url.endsWith(".pdf") ? (
                  <FilePdfOutlined style={{ fontSize: 60 }} />
                ) : (
                  <FileImageOutlined style={{ fontSize: 60 }} />
                )}
              </button>
            </Tooltip>
          ))}
        </div>
      </div>
    </div>
  );
};
