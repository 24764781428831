import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
} from "../utils/api";

export const TaskService = {
  index: async ({limit, offset, is_completed, filters}) => {
    let query = `/tasks?limit=${limit}&offset=${offset}`;
    if (is_completed !== "") {
      query += `&is_completed=${is_completed}`;
    }
    if (filters) {
      for (const [key, value] of Object.entries(filters)) {
        if (value) query += `&${key}=${value}`;
      }
    }
    return await getRequest(query);
  },

  create: async (body) => {
    const dados = await postRequest("/task", body);
    return dados;
  },

  update: async (body) => {
    const dados = await putRequest(`/task?id=${body.id}`, body);
    return dados;
  },

  delete: async (id) => {
    const dados = await deleteRequest(`/task?id=${id}`, {});
    return dados;
  },
};



