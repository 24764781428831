import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Main from "../../../Layout/Main";
import MenuComponent from "../../../../components/MenuComponent";
import { itensMenu } from "../itens-menu";
import { Button, Table, Tooltip } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { useState } from "react";
import { ContractApprover } from "../../../../utils/apiContractApprover";
import { useSelector } from "react-redux";


function PlanContractApproval(props) {

  const signin = useSelector((state) => state.signin);


  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
    total: 5,
  });

  const breadcrumb = [
    {
      title: "Comercial",
      path: props.modulePath,
    },
    {
      title: "Aprovação de Contratos de Plano",
      path: props.modulePath + "/plan-contract-approval",
    },
  ];

  const { code_erp: subsidiary_erp, Hierarquia } = signin.user.userCompanies.find(
    (company) => company.id === signin.user.currentCompany
  );

  const getInfosContractsProtheus = async () => {
    setLoading(true)
    try {
      const { data } = await ContractApprover.getContracts(`${Hierarquia.code_erp},${subsidiary_erp}`, signin.user.seller_code, pagination.pageSize, (pagination.current - 1) * pagination.pageSize);
      setDataSource(data.records);
      var control = pagination;
      control.total = data.metadata.total_record_count
      setPagination(control)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const columns = [
    {
      title: 'Contrato',
      dataIndex: 'numero_proposta',
      key: 'numero_proposta',
    },
    {
      title: 'Nome do cliente',
      dataIndex: 'nome_cliente',
      key: 'nome_cliente',
    },
    {
      title: 'Data de emissão',
      dataIndex: 'data_emissao',
      key: 'data_emissao',
    },
    {
      title: 'Código do plano',
      dataIndex: 'codigo_plano',
      key: 'codigo_plano',
    },
    {
      title: 'Nome do plano',
      dataIndex: 'nome_plano',
      key: 'nome_plano',
    },
    {
      title: 'Valor do plano',
      dataIndex: 'valor_plano',
      key: 'valor_plano',
      render: (data) => {
        return data.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        })
      }
    },
    {
      title: 'Nome do vendedor',
      dataIndex: 'nome_vendedor',
      key: 'nome_vendedor',
    },
    {
      title: 'Ativação',
      dataIndex: "actions",
      render: (active, record) => {
        return <Tooltip title="Ativar"> <Button type="primary"
          shape="circle"
          ghost size="large"
          icon={<EditOutlined />}
          onClick={() => { }} href={`/${props.modulePath}/contract-detail/${Hierarquia.code_erp}/${subsidiary_erp}/${record.numero_proposta}`}
        ></Button></Tooltip>;
      },
    },
  ];

  useEffect(() => {
    getInfosContractsProtheus()
  }, [pagination])

  async function handleTableChange(pagination) {
    setPagination(pagination)
    console.log(pagination)
  }


  return (
    <>
      <Helmet>
        <title>Aprovação de Contratos de Plano - webGM</title>
      </Helmet>
      <Main
        moduleName={props.moduleName}
        moduleMenu={
          <MenuComponent
            itensMenu={itensMenu}
            modulePath={props.modulePath}
            selectedMenu="1"
          />
        }
        breadcrumb={breadcrumb}
      >
        <Table
          dataSource={dataSource}
          columns={columns}
          loading={loading}
          onChange={handleTableChange}
          scroll={{ x: 800 }}
          pagination={pagination}
        />
      </Main >
    </>
  );
}

export default PlanContractApproval;
