import "./styles.css";

import { itensMenu } from "./itens-menu";
import MenuCard from "../../../components/menu_card";

function HomeManagement(props) {

  props = {
    ...props,
    breadcrumb: [
      {
        title: "Gestão",
        path: props.modulePath,
      },
    ],
    itensMenu: itensMenu,
  };

  return <MenuCard {...props} />;
}

export default HomeManagement;

